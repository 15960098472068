body {
  color: #444438
}

.card {
  border-radius: 4px;
  border: 2px grey solid;
  padding: 1rem;
}

.patient-summary {
  margin: 1rem auto;
  max-width: 1200px;
}

.patient-summary__name {
  margin: 1rem;
}

.patient-summary__id {
  margin-left: 1rem;
}

.patient-summary__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin: 1rem;
}

.patient-summary__cards .chart-card {
  grid-column: span 3;
}

.patient-summary__cards .kpi-card {
  grid-column: span 1;
}

.chart-card__title {
  font-weight: 700;
  text-align: center;
  margin: 0.5rem;
}

.kpi-card {
  display: flex;
  flex-direction: column;
  min-height: 13rem;
}

.kpi-card__label {
  font-weight: 700;
  text-align: center;
  margin: 0.5rem;
}

.kpi-card__value {
  font-weight: 500;
  font-size: 7rem;
  text-align: center;
  align-self: center;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: -0.25rem;
}

.reports {
  padding: 1rem;
  min-height: calc(100vh - 2rem);
}

.patient-group {
  display: grid;
  grid-template-rows: auto 1fr;
}
.patient-group__title {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 300;
}

.patients {
  /* margin: auto; */
  /* display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin: auto; */
  max-width: 1200px;
}

.patients__header {
  font-size: 2rem;
  margin-bottom: .75rem;
}
.patients__header-caption {
  text-transform: uppercase;
  font-size: small;
  margin-bottom: 0.75rem;
  margin-top: -0.25rem;
}

.patient-group .patient-group__not-found {
  margin: auto;
  margin-top: max(calc(50vh - 12rem), 0px);
}

.patient-link {
  margin: 0.25rem;
  display: flex;
}

.patient-link a {
  text-decoration: none;
  font-size: 1.5rem;
  /* display:block;
  border-radius: 6px;
  border: 2px solid blue;
  color: blue;
  text-align: center;
  padding: 0.5em;
  width: 90px;
  height: 50px; */
}

.patient-chart__error {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  /* background: rgba(255, 255, 255, 55%); */
  display: grid;
  grid-template-rows: 1fr;
}

.patient-chart__error-message {
  font-weight: 700;
  width: 100%;
  text-align: center;
  align-self: center;
}

#login {
  margin-top: calc(50vh - 20rem);
}
#login input,
#login button {
  display: block;
  margin: auto;
  /* padding: 0; */
}

#login h2 {
  font-weight: 300;
}

#login h3 {
  font-weight: 500;
}

#login label {
  display: block;
  width: 100%;
  margin: auto;
  margin-top: 4px;
  font-weight: 700;
  padding: 4px;
}

#login img.sonde-logo {
  height: 8rem;
  width: auto;
  margin: auto;
  display: block;
  margin-bottom: 2rem;
  /* text-align: center; */
}

.login__field {
	/* padding: 20px 0px; */
	position: relative;
	width: min(75%, 20rem);
  margin: auto;
}

.login__icon {
	position: absolute;
  top: 34px;
  left: 4px;
  font-size: 24px;
	color: #6D94AC;
}

.login__icon.login__icon-right {
  right: 4px;
  left: unset;
}

.login__visibility {
  cursor: pointer;
}

#login input {
  box-sizing: border-box;
	border: none;
	border-bottom: 2px solid #6D94AC;
	background: none;
	padding: 10px;
	padding-left: 34px;
	font-weight: 700;
	width: 100%;
	transition: .2s;
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #1c4863;
}

.login__submit {
	background: #fff;
	font-size: 14px;
	margin-top: 30px;
	padding: 16px 20px;
	border-radius: 26px;
	border: 2px solid #7faac5;
	text-transform: uppercase;
	font-weight: 700;
	display: block;
	align-items: center;
	color: #1c4863;
	cursor: pointer;
	transition: .2s;
	width: min(75%, 20rem);
}

.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #6A679E;
	outline: none;
}


/* #login input[type="email"] {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

#login input[type="password"] {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
} */

.card {
  position: relative;
}

.fill-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 55%);
  z-index: 999;
}

.fill-container.fill-container__screen {
  position: fixed;
  user-select: none;
}

.fill-container .dot-spin {
  top: 50%;
  margin-left: auto;
  margin-right: auto;
}

.fill-container .dot-falling {
  top: 50%;
  margin: auto;
  text-align: initial;
}

#login button {
  margin: 0;
  margin-top: 2rem;
  padding: 10px;
  width: 100%;
}

#login h2, h3 {
  text-align: center;
  margin: auto;
}

#login h3 {
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}

#login .login__error {
  color: darkred;
  font-size: small;
  text-align: center;
  margin-top: 1rem;
}

.reports {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 2rem 1fr;
  gap: 1rem 0px;
  grid-template-areas:
    "nav title logout"
    "content content content"
    "footer footer footer";
  max-width: 1200px;
  margin: auto;
}

.reports > #logout {
  grid-area: logout;
  text-align: right;
}

.reports > #back-nav {
  grid-area: nav;
}

.reports > .patient-group {
  grid-area: content;
}

.reports > .patient-summary {
  grid-area: content;
}
.reports > .reports__organization-title {
  grid-area: title;
  text-align: center;
  font-weight: 700;
}

.reports__organization-title--large {
  font-size: 4em;
  font-weight: 500 !important;
}

.reports > #login {
  grid-area: content;
}

.reports > .footer {
  grid-area: footer;
}

.footer {
  margin-top: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "_ _ privacy"
    "copyright version tos";
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer a:hover {
  text-decoration: underline;
  color: inherit;
}

.footer > .version {
  grid-area: version;
  text-align: center;
  font-size: small;
}

.footer > .privacy {
  grid-area: privacy;
  text-align: right;
}

.footer > .tos {
  grid-area: tos;
  text-align: right;
  white-space: nowrap;
}

.footer > .copyright {
  grid-area: copyright;
  white-space: nowrap;
}

#logout .username {
  padding-right: 1rem;
  overflow: hidden;
  white-space: nowrap;
}

.user-display-settings {
  margin: 0px auto;
  grid-area: content;
  min-width: 500px;
}
.user-display-settings__container {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
}

.user-display-settings__section-title {
  font-size: 1.5rem;
  padding: 1.5rem;
  text-align: center;
}
.user-display-settings__sub-section-title {
  font-size: 1rem;
  font-weight: 700;
  margin-top: .5rem;
  padding: .5rem;
}

.user-display-settings__option {
  font-size: 1rem;
  padding: 0.5rem;
}

.user-display-settings__option label {
  margin: 0.25rem;
}

.user-display-settings__option input {
}

.user-display-settings__link {
  text-decoration: none;
  font-size: 1.5rem;
  color: #444438;
  padding: 0.25rem;
}

.user-display-settings__link:hover {
  color: #6D94AC;
}

a {
  color: #6D94AC;
}
a:hover {
  color: #444438;
}

.key {
  border-width: 2px;
  border-radius: 4px;
}
.key--valid {
  border-color: #0BDA51;
}
.key--invalid {
  border-color: #FF5733;
}
